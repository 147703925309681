import React from 'react';
import styled from "styled-components"
//
import P1a from "./p1-a"
import P2a from "../imgs/p2-a";
import P3a from "../imgs/p3-a";
import P4a from "../imgs/p4-a";
import P5a from "../imgs/p5-a";
import P6a from "../imgs/p6-a";
import Service1 from "../imgs/Service1";
import Service2 from "../imgs/Service2";
import Service3 from "../imgs/Service3";
import Service4 from "../imgs/Service4";
import Service5 from "../imgs/Service5";
import Service6 from "../imgs/Service6";
import Service7 from "../imgs/Service7";
import Service8 from "../imgs/Service8";

const BorderedImgContainer = styled.div`
	display:flex;
	position:relative;
	justify-content:center;
	align-items:center;
	&.clickable{
		cursor: pointer;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 200ms;
		transition-property: all;
		:hover, :focus{
			transform: scale(1.1);
		}
	}
`;
const BorderBase = styled.div`
	display:block;
	background-color:${props => props.theme.red};
	min-height:3px;
	min-width:3px;
	position:absolute;
	z-index:10;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 550ms;
	transition-property: top, left, bottom, right, width, height;
`;
const BorderTop = styled(BorderBase)`
	top:7%;
	width:99%;
	margin-left:1%;
	transition-delay: 125ms;
	&.shrink{
		top:30%;
		width:49%;
	}
`
const BorderRight = styled(BorderBase)`
	right:7%;
	height:95%;
	transition-delay: 75ms;
	&.shrink{
		right:30%;
		height:45%;
	}
`
const BorderBottom = styled(BorderBase)`
	bottom:7%;
	width:97%;
	margin-left:1%;
	transition-delay: 150ms;
	&.shrink{
		bottom:30%;
		width:47%;
	}
`
const BorderLeft = styled(BorderBase)`
	left:7%;
	height:100%;
	transition-delay: 100ms;
	&.shrink{
		left:30%;
		height:50%;
	}
`
const BorderImgMask = styled.div`
	display:block;
	position:absolute;
	z-index:5;
	width:86%;
	height:86%;
	overflow:hidden;
	opacity:1;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 550ms;
	transition-property: width, height, opacity;
	transition-delay: 200ms;
	&.shrink{
		width:40%;
		height:40%;
		opacity:0;
	}
`;

const BorderImgSpacer = styled.div`
	display:flex;
	width:100%;
	height:100%;
	visibility: hidden;
`;

const getProjectImage = (pid) => {
	switch(pid){
		case "p1":
			return (<P1a />);
		case "p2":
			return (<P2a />);
		case "p3":
			return (<P3a />);
		case "p4":
			return (<P4a />);
		case "p5":
			return (<P5a />);
		case "p6":
			return (<P6a />);
		case "cons":
			return (<Service1 />);
		case "feas":
			return (<Service2 />);
		case "plan":
			return (<Service3 />);
		case "cond":
			return (<Service4 />);
		case "breg":
			return (<Service5 />);
		case "asvs":
			return (<Service6 />);
		case "strt":
			return (<Service7 />);
		case "comp":
			return (<Service8 />);
		default:
			return (<P1a />);
	}
	;
}

const BorderedImg = React.forwardRef(({ size, interact, onSelected, pid }, ref) => {

	const doesInteract = interact ? true : false;
	const img = getProjectImage(pid)
	return (
		<BorderedImgContainer
			ref={ref}
			className={`
				${doesInteract ? 'clickable' : ''}
			`}
			onClick={() => {
				if(onSelected){
					onSelected(ref, pid)
				}
			}}
		>
			<BorderTop className={size === "full" ? "" : "shrink"}/>
			<BorderRight className={size === "full" ? "" : "shrink"}/>
			<BorderBottom className={size === "full" ? "" : "shrink"}/>
			<BorderLeft className={size === "full" ? "" : "shrink"}/>
			<BorderImgMask className={size === "full" ? "" : "shrink"}>
				{img}
			</BorderImgMask>
			<BorderImgSpacer>
				{img}
			</BorderImgSpacer>
			{/* <P1a style={{visibility:"hidden"}} className="hidden" /> */}
		</BorderedImgContainer>
	);
});

export default BorderedImg;